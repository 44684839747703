<template>
  <div>
    <el-dialog :title="text+'讲义类别'"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               v-if="dialogVisible"
               :validate-on-rule-change="false"
               ref="formRefs"
               label-width="120px">

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="类别名称"
                          prop="lecture_category_name">
              <el-input clearable
                        placeholder="请输入"
                        v-model="form.lecture_category_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="所属科目"
                          prop="subject_id">
              <el-select style="width:100%"
                         v-model="form.subject_id"
                         placeholder="选择科目">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="所属轮次"
                          prop="lecture_turn_id">
              <el-select style="width:100%"
                         v-model="form.lecture_turn_id"
                         placeholder="选择科目">
                <el-option v-for="item in lunciList"
                           :key="item.lecture_turn_id"
                           :label="item.lecture_turn_name"
                           :value="item.lecture_turn_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      text: '新增',
      form: {},
      subjectList: [],
      lunciList: [],
      rules: {
        lecture_category_name: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        subject_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        lecture_turn_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ]
      },
      treeList: [],
    }
  },
  methods: {
    getSubjectList () {
      this.subjectList = JSON.parse(JSON.stringify(this.$parent.subjectList))
      this.subjectList.shift()

      this.$http({
        url: '/api/v1/lecture/turn',
        method: 'get',
        params: {
          page: 1,
          limit: 10000
        }
      }).then(res => {
        this.lunciList = res.data.list
      })
    },
    handleClose () {
      this.form = {}
      this.$refs.formRefs.resetFields()
      this.dialogVisible = false
    },
    handleSave () {
      let form = JSON.parse(JSON.stringify(this.form))
      this.$refs.formRefs.validate(val => {
        if (!val) return
        this.$http({
          url: '/api/v1/lecture/add_category',
          method: 'POST',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.$parent.search()
          this.handleClose()
        })
      })

    },

  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.tree_warp {
  height: 350px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>